.ce-rawtool__textarea {
  min-height: 200px;
  resize: vertical;
  border-radius: 8px;
  border: 0;
  background-color: #1e2128;
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #a1a7b6;
  overscroll-behavior: contain;
}